import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';
import React from 'react';
import { LiveEditor, LiveError, LivePreview, LiveProvider } from 'react-live';
import styled from 'styled-components';
import { copyToClipboard } from '../utils/copy-to-clipboard';

const Pre = styled.pre`
	position: relative;
	text-align: left;
	margin: 1em 0;
	padding: 24px 16px;
	margin: 16px 0;
	overflow-x: auto;
	border-radius: 6px;
	background-color: #31485f;
	max-height: 300px;

	& .token-lline {
		line-height: 1.3em;
		height: 1.3em;
	}
`;

const LineNo = styled.span`
	display: inline-block;
	width: 2em;
	user-select: none;
	opacity: 0.3;
`;

const CopyCode = styled.button`
	position: absolute;
	right: 7px;
	border: 0;
	border-radius: 3px;
	margin: 2px;
	opacity: 0.3;
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
`;

export const Code = ({ codeString, language, ...props }) => {
  if (props['react-live']) {
    return (
      <LiveProvider code={codeString} noInline={true} theme={theme}>
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    )
  }
  const handleClick = () => {
    copyToClipboard(codeString)
  }
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({
        className,
        style,
        tokens,
        getLineProps,
        getTokenProps,
      }) => (
        <Pre className={className}>
          {/* <CopyCode onClick={handleClick}>Copy</CopyCode> */}
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              <LineNo>{i + 1}</LineNo>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Pre>
      )}
    </Highlight>
  )
}
